import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAppConfig from "../../hooks/useAppConfig";
import Footer from "../../Layout/Footer";
import HeroBanner from "../../Layout/HeroBanner";
import HomeHeader from "../Offers/HomeHeader";

const Home = () => {
  const [bannerImg, setBannerImg] = useState();
  const [footerImg, setFooterImg] = useState();
  const pwaConfig = useAppConfig();
  useEffect(() => {
    setBannerImg(
      pwaConfig?.appConfig?.config?.modules?.OFFER?.headerImage
        ? pwaConfig?.appConfig?.config?.modules?.OFFER?.headerImage
        : pwaConfig?.appConfig?.config?.modules?.HOME?.headerImage
    );
    setFooterImg(
      pwaConfig?.appConfig?.config?.modules?.OFFER?.footerImage
        ? pwaConfig?.appConfig?.config?.modules?.OFFER?.footerImage
        : pwaConfig?.appConfig?.config?.modules?.HOME?.footerImage
    );
  }, [pwaConfig]);
  return (
    <>
      <Container sx={{ py: 3 }}>
        <HeroBanner src={bannerImg} />
        <HomeHeader />
        <Outlet />
        <Footer img={footerImg} />
      </Container>
    </>
  );
};

export default Home;
