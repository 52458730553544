import {
  Box,
  Card,
  Container,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  AppBar as MuiAppBar,
  Button,
  Typography,
  Tab,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

export const StyledTab = styled(Tab)((props) => {
  return {
    border: `1px solid ${props.backgrounds}`,
    color: props.backgrounds,
    "&.Mui-selected": {
      color: "white",
      backgroundColor: props.backgrounds,
    },
  };
});

export const Activated = styled(Button)(({ theme }) => ({
  height: 30,
  fontSize: 12,
  borderRadius: 10,
}));

export const Headers = styled("header")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  marginBottom: "10px",
});

export const OfferCard = styled(Card)({
  padding: 20,
  paddingTop: 10,
  paddingBottom: 30,

  marginBottom: 20,
  borderRadius: 4,
  boxShadow:
    "rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px",
});

export const OnlyforYouBox = styled(Typography)({
  bottom: "25px",
  position: "relative",
  left: "210px",
  backgroundColor: "rgb(255, 246, 217)",
  borderRadius: "15px",
  width: "135px",
  height: "40px",
});

export const GenericBox = styled(Typography)({
  bottom: "30px",
  position: "relative",
  left: "240px",
  backgroundColor: "rgb(255, 246, 217)",
  borderRadius: "0px 28px",
  width: "135px",
  height: "40px",
});

export const Textval = styled(Typography)({
  position: "relative",
  fontSize: 10,
  paddingTop: 15,
});

export const OfferCode = styled(Typography)({
  backgroundColor: "#F3F3F3",
  padding: 10,
  fontWeight: 600,
  fontSize: 12,
  height: 35,
  borderRadius: 10,
  boxShadow:
    "rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px",
});

export const ActiveBox = styled(Typography)({
  position: "relative",
  left: "35px",
  top: "8px",
  backgroundColor: "#e6ffeb",
  borderRadius: "50%",
  width: "100px",
  height: "90px",
});

export const Active = styled(Typography)({
  position: "relative",
  fontWeight: 600,
  fontSize: 14,
  paddingTop: 15,
  color: "#429554",
  left: "25px",
  top: "2px",
});

export const Reedemed = styled(Typography)({
  position: "relative",
  left: "18px",
  top: "10px",
  fontWeight: 600,
  fontSize: 14,
  paddingTop: 14,
  color: "#F26E5F",
});

export const LoyaltyInfoContainer = styled(Container)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: theme.spacing(2),
  paddingBlock: theme.spacing(3),
}));

export const LoyaltyPointsCard = styled(Card)({
  ".MuiCardContent-root": {
    maxWidth: 800,
    marginInline: "auto",
    textAlign: "center",
    display: "grid",
    gap: "2rem",
    padding: 20,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
  },
  ".loyaltyChart": {
    maxWidth: 261,
    marginInline: "auto",
  },
});

export const DateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  borderTop: "10px solid white",
  borderBottom: "10px solid white",
}));

export const TextBox = styled(TextField)(({ theme }) => ({
  marginBottom: 30,
  width: "275px",
}));

export const AppBar = styled(MuiAppBar)(({ theme }) => {
  const { palette } = theme;
  return {
    background: palette.background.paper,

    ".navCol1": {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
    },
    ".navContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBlock: ".5rem",
      borderBottom: `1px solid ${palette.divider}`,
    },
    [theme.breakpoints.up("md")]: {
      ".menuBtn": {
        display: "none",
      },
    },
  };
});

export const NavLinks = styled("nav")(({ theme }) => ({
  ul: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    a: {
      display: "inline-block",
      borderBottom: "2px solid transparent",
      color: theme.palette.primary.main,
      // "&.active": {
      //   borderColor: theme.palette.primary.main,
      //   color: theme.palette.primary.main,
      // },
    },
  },
}));

export const LoaderContainer = styled("div")({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  main: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
  },
});

export const LinkGroup = styled("ul")((props) => {
  return {
    a: {
      color: props.color,
      fontSize: "1.125rem",
      textDecoration: "none",
    },
  };
});

export const BrandLink = styled(Link)({
  display: "flex",
  img: { maxWidth: `clamp(45px, 8vw, 70px)` },
});

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  textAlign: "center",
};

export const TransactionCard = styled(Card)({
  margin: "20px 10px",
  padding: "15px",
  borderRadius: "4px",
  boxShadow:
    "rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px",
});

export const InvoiceBtn = styled(Button)({
  height: "30px",
  padding: "4px",
  paddingRight: "10px",
  color: "#333333",
  backgroundColor: "#EFEFEF",
  fontWeight: 600,
  borderRadius: "6px",
  "&:hover": {
    backgroundColor: "#EFEFEF",
  },
  "&:active": {
    backgroundColor: "lightgray",
  },
});

export const ErrorHead = styled(Typography)({
  textAlign: "center",
  fontFamily: "Nunito",
  fontWeight: 700,
  color: "#F07663",
  fontSize: 24,
  marginBottom: 10,
});

export const ErrorDetails = styled(Typography)({
  textAlign: "center",
  fontFamily: "Nunito",
  fontWeight: 400,
  color: "#656565",
});

export const ErrorContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  height: "100vh",
  padding: 40,
});
