import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import useAppConfig from "../../../hooks/useAppConfig";
import useAppTheme from "../../../hooks/useAppTheme";
import useAuth from "../../../hooks/useAuth";
import { getConfig } from "../../../service/pwaConfig";
import { BrandLink } from "../../../styles/styledComponent";

const Brand = () => {
  const pwaConfig = useAppConfig();
  const [businessUnit, setBusinessUnit] = useState([]);
  const auth = useAuth();
  const Config = useAppConfig();
  const { breakpoints } = useTheme();
  const { theme } = useAppTheme();
  let color = theme.palette.primary.main;

  const isMobileView = useMediaQuery(breakpoints.down("md"));
  const [logo, setLogo] = useState();
  useEffect(() => {
    setLogo(pwaConfig?.appConfig?.config?.logo);
    setBusinessUnit(pwaConfig?.appConfig?.businesses);
  }, [pwaConfig, auth]);

  const handleChange = async (event) => {
    const pwaConfig = await getConfig(event.target.value);
    Config.setAppConfig(pwaConfig);
  };
  return (
    <div className="flexSpace">
      <BrandLink style={{ marginRight: 40 }}>
        <img src={logo} alt="" />
      </BrandLink>
      <FormControl style={{ width: 150, marginTop: 10 }}>
        <InputLabel
          id="demo-simple-select-label"
          style={
            isMobileView
              ? { position: "absolute", fontSize: 13, color: color }
              : { color: color }
          }
        >
          Business Unit
        </InputLabel>
        <Select
          style={isMobileView ? { height: 40, fontSize: 13, textTransform: 'capitalize' } : { textTransform: 'capitalize' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="BusinessUnit"
          defaultValue={auth?.user?.business}
          onChange={handleChange}
        >
          {businessUnit &&
            businessUnit.map((res) => {
              return <MenuItem style={{ "textTransform": "capitalize" }} value={res.name}>{res.name}</MenuItem>;
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default Brand;
