import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Headers } from "../../styles/styledComponent";
import UserProfile from "../LoyaltyInfo/UserProfile";
import { ReactComponent as ProfilePic } from "../../assets/profile_icon.svg";
import HeroBanner from "../../Layout/HeroBanner";
import Footer from "../../Layout/Footer";
import useAppConfig from "../../hooks/useAppConfig";

const Profile = () => {
  const [bannerImg, setBannerImg] = useState("");
  const [footerImg, setFooterImg] = useState("");
  const pwaConfig = useAppConfig();

  useEffect(() => {
    setBannerImg(
      pwaConfig?.appConfig?.config?.modules?.PROFILE?.headerImage
        ? pwaConfig?.appConfig?.config?.modules?.PROFILE?.headerImage
        : pwaConfig?.appConfig?.config?.modules?.HOME?.headerImage
    );
    setFooterImg(
      pwaConfig?.appConfig?.config?.modules?.PROFILE?.footerImage
        ? pwaConfig?.appConfig?.config?.modules?.PROFILE?.footerImage
        : pwaConfig?.appConfig?.config?.modules?.HOME?.footerImage
    );
  }, [pwaConfig]);
  return (
    <>
      <Container>
        <HeroBanner src={bannerImg} />
        <Headers>
          <Box style={{ display: "flex", marginTop: 20 }}>
            <ProfilePic className="profileIcon" />
            <Typography style={{ fontSize: 25, fontWeight: 600 }}>
              Profile
            </Typography>
          </Box>
        </Headers>
        <UserProfile />
        <Footer img={footerImg} />
      </Container>
    </>
  );
};

export default Profile;
