import axios from "axios";
import config from "../config";
const { ruleServerUrl } = config;

export const getLoyalty = (mobileNumber) => {
  return axios
    .get(`${ruleServerUrl}/customer-profile/info/${mobileNumber}`)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

export const getImmediateExpiringPoints = async (business) => axios
  .get(`${ruleServerUrl}/pwa/loyalty/immediate-expiry-points?business=${business}`).then(({ data }) => data)

export const getUserLoyaltyData = async (business) => {
  const immediateExpiringPoints = await getImmediateExpiringPoints(business)
  return { immediateExpiringPoints }
}

export const getLoyaltyInfo = (business) => {
  return axios
    .get(`${ruleServerUrl}/pwa/loyalty?business=${business}`)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

export const getLoyaltyPointsHistory = (Business) => {
  return axios
    .get(
      `${ruleServerUrl}/pwa/customer-profile/customerWithPointsExpiryInfo?business=${Business}`
    )
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

export const getTransactionHistory = (Business) => {
  return axios
    .get(`${ruleServerUrl}/pwa/customer-profile/details?business=${Business}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
};

export const downloadInvoice = (bill) => {
  return axios
    .post(`${ruleServerUrl}/pwa/invoice-details/pdf`, bill, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${bill.billId}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      return err;
    });
};
