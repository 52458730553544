import React, { useEffect, useState } from "react";
import { Alert, createTheme, ThemeProvider } from "@mui/material";
import { useParams } from "react-router-dom";
import CustomerVerifyStyles from "./CustomerVerify.module.css";
import HeroBanner from "../../Layout/HeroBanner";
import Footer from "../../Layout/Footer";
import { getSignup } from "../../service/dynamicForm";
import _ from "lodash";
import { Typography } from "@mui/material";
import OtpInput from "react-otp-input";
import { canAllowEdit, onEditAllowed } from "../../shared/validations";
import { 
  validateCustomer,
  sendOtp,
  verifyOtp 
} from '../../service/customerVerification';
import { 
  StyledBox, 
  otpContainerStyles, 
  otpInputStyes,
  StyledButton,
  StyledTextField
} from './MuiComponentStyles'; 
import {CircularProgress} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(242, 117, 95, 1)'
    },
  },
  components: {
    MuiButton: {
      defaultProps:{
        disableElevation: true
      }
  }
  }
});

function CustomerVerify() {
  const { tenant='', business='' } = useParams();
  const [otp, setOtp] = useState("");
  const [pwaImages, setPwaImages] = useState({});
  const [mobile, setMobile] = useState('');
  const [customerInfo, setCustomerInfo] = useState({});
  const [isValidOtp, setIsValidOtp] = useState({status: false, isVerified: false});
  const [actionValue, setActionValue] = useState('Login');
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLowestMobileWidth, setIsLowestMobileWidth] = useState(window.innerWidth < 360);
  const [resendLoader, setResendLoader] = useState(false);

  useEffect(() => {
    setIsLowestMobileWidth(window.innerWidth < 360);
  }, []);

  useEffect(() => {
    getSignup(tenant, business).then((res) => {
      setPwaImages({
        headerImage: res?.modules?.HOME?.headerImage,
        footerImage: res?.modules?.HOME?.footerImage
      })
    });
  }, [business, tenant]); 

  useEffect(() => {
    if(otp.length !== 6){
      setIsValidOtp({
        status: false,
        isVerified: false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp])

  useEffect(() => {
    if(_.get(customerInfo, 'status') === 'New'){
      setActionValue('Register')
    } else {
      setActionValue('Login')
    }
  }, [customerInfo])

  const validateOTP = () => {
    verifyOtp(mobile, tenant, business, otp)
    .then((res) => {  
      (_.get(customerInfo, 'status') === 'New') ? 
      window.location.replace(`${customerInfo.redirectUrl}/?mobile=${mobile}`) :
      window.location.replace(`${customerInfo.redirectUrl}`)
      })
    .catch(err => {
      setIsValidOtp({
        status: false,
        isVerified: true
      })
    })
  }

  const getCustomerInfo = (mobile) => {
    validateCustomer(mobile, tenant, business)
        .then((response) => {
          setIsLoading(false)
          setCustomerInfo(response);
        })
        .catch((error) => {
          setIsLoading(false)
          console.error(error);
        })
  }

  const clearData = () => {
    setCustomerInfo({});
  }

  const handleChange = (e) => {
    setMobile(getValueAfterValidation(e.target.value));
    if(e.target.value.length === 10){
      setIsLoading(true);
      getCustomerInfo(e.target.value);
    } else {
      clearData()
    }
  }

  const getValueAfterValidation = (mobileNumber) => {
    if (canAllowEdit('mobile', mobileNumber)) {
      return onEditAllowed('mobile', mobileNumber);
    }
    return mobile
  }

  const handleError = () => {
    return mobile.length >= 1 && mobile.length < 10
  }

  const generateOtp = (message="") => {
    sendOtp(mobile, tenant, business)
      .then((response) => {
        if(response?.status === 'success'){
          alert(`${message}`)
          setResendLoader(false)
          setIsOtpSend(true);
          setIsLoading(false)
        } else {
          alert('Error occured')
        }
        })
      .catch((error) => { 
        setIsLoading(false)
        setResendLoader(false)
        console.log(error)
      })
  }

  const getHelperText = () => {
    if(mobile.length >= 1 && mobile.length < 10){
      return 'Enter a valid 10 digit mobile number'
    } else {
      return ''
    }
  }

  const renderOtpLoginModule = () => {
    return (
      <div className={CustomerVerifyStyles.otpVerifyModule}>
          <div className={CustomerVerifyStyles.otpContainer}
          style={{
            pointerEvents: mobile.length !== 10 ? 'none' : ''
          }}>
            <OtpInput
              inputType="number"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span> </span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={
                otpInputStyes()
              }
              containerStyle={{
                  ...otpContainerStyles(),
                  pointerEvents: (otp.length === 6 && isValidOtp.status && isValidOtp.isVerified) ? 'none' : ''
                }}
            />
          <div className={CustomerVerifyStyles.otpResendContainer}>
            <Typography variant="p" style={{color: '#A7A7A7'}}>
              Didn't recieve OTP?
            </Typography>
            <span className={CustomerVerifyStyles.resendButton}
            style={resendLoader ? {color: '#A7A7A7', pointerEvents: 'none'} : {}}
            onClick={() => {
              setResendLoader(true)
              setIsValidOtp({
                status: false,
                isVerified: false
              })
              generateOtp('OTP resent successfully')}}
            >
              Resend OTP
          </span>
          </div>
          </div>
          <StyledButton
            disabled={otp.length !== 6}
            variant="contained"
            style={{
              backgroundColor: otp.length === 6 ?  'rgba(242, 117, 95, 1)' : 'rgba(242, 117, 95, 0.4)',
              color: "white"
            }}
            onClick={() => {
              validateOTP()
            }}
          >
            {isLoading ? <CircularProgress style={{width: '27.5px', height:'27.5px'}}/> : actionValue}
          </StyledButton>
          </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={CustomerVerifyStyles.container}>
        <header>
        <HeroBanner src={_.get(pwaImages, "headerImage")} />
        <StyledBox>
          <Typography
            style={{
              color: "rgba(51, 51, 51, 1)",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {isOtpSend ?  actionValue : 'Enter Mobile Number'}
          </Typography>
          {
              otp.length === 6 && !isValidOtp.status && isValidOtp.isVerified &&
              <Alert severity="error" style={{
                width: isLowestMobileWidth ? "260px" : '340px'
              }}>
                  Invalid OTP
              </Alert>
            }
            {/* {
              otp.length === 6 && isValidOtp.status && isValidOtp.isVerified &&
              <Alert severity="success" style={{
                width: isLowestMobileWidth ? "260px" : '340px'
              }}>
                  OTP verified
              </Alert>
            } */}
          <StyledTextField
            type="number"
            key={'mobile'}
            name='Mobile Number'
            variant="outlined"
            label="Mobile Number"
            value={mobile}
            disabled={isOtpSend}
            onChange={handleChange}
            error={handleError()}
            helperText={getHelperText()}
          />
          { isOtpSend ?
            renderOtpLoginModule() : 
            <StyledButton variant="filled" style={{
              backgroundColor: (_.isEmpty(customerInfo) || isLoading) ?  
              'rgba(242, 117, 95, 0.4)' : 'rgba(242, 117, 95, 1)',
              color: "white"
            }}
            disabled={_.isEmpty(customerInfo) || isLoading}
            onClick={() => {
              setIsLoading(true)
              generateOtp('OTP successfully sent')
              }}>
              {isLoading ? <CircularProgress style={{width: '27.5px', height:'27.5px'}}/> : 'SEND OTP'}
            </StyledButton>
        }
        </StyledBox>
        </header>
        <footer>
        <Footer img={_.get(pwaImages, "footerImage")} />
        </footer>
      </div>
    </ThemeProvider>
  );
}

export default CustomerVerify;
