/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
/* eslint-disable no-useless-escape */
import {
  Button,
  Card,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Layout/Footer";
import HeroBanner from "../../Layout/HeroBanner";
import {
  getSignup,
  registerWithoutCaptcha,
  userUpsert,
} from "../../service/dynamicForm";
import moment from "moment";
import { getParamsFromUrl } from "../../shared/utils"
import { SocialMediaChannel } from "../../shared/constants/SocialMedia"
import _ from "lodash";
import { EMAIL_REGEXP } from "../../shared/constants/RegExp";
import SelectField from "./SelectField";
import {gender} from './constants';
import { canAllowEdit, onEditAllowed } from "../../shared/validations";
import NikeComponent from "./NikeComponent/NikeComponent";
import styles from './NikeComponent/NikeComponent.module.css';
import ThankYou from "./ThankYou";

const DynamicForm = () => {
  const [pwa, setPwa] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [bannerImg, setBannerImg] = useState("");
  const [footerImg, setFooterImg] = useState("");
  const [status, setStatus] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loader, setLoader] = useState(true);
  const [thankYouScreen, setThankYouScreen] = useState("");
  const [splashScreen, setSplashScreen] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState({});
  const [agreed, setAgreed] = useState(false)
  const [isFromOneFlow, setIsFromOneFlow] = useState(false)

  const maxDate = new Date().toISOString();

  const { tenant, business='' } = useParams();

  const assignSelectFieldArray = (name) => {
    if(name === 'gender') {
      return gender
    }
    return [];
  }

  const setInitialValues = (fields) => {
    return { ...setMobileFromQueryParams()}
  }

  const setMobileFromQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    if(params.has("mobile")){
      setIsFromOneFlow(true)
      return {
        mobile: params.get("mobile")
      }
    } else {
      return {}
    }
  }

  useEffect(() => {
    if(isSubmit){
    const timer = setTimeout(() => {
          const baseUrl = (window.location.href.substring(0, window.location.href.indexOf("sign")))
          window.location.replace(`${baseUrl}customer-verify/${tenant}/${business}`)
    }, 3000)
    return () => clearInterval(timer)
    }
  }, [isSubmit]);

  useEffect(() => {
    getSignup(tenant, business).then((res) => {
      const loyaltySignUpForm = res?.loyaltySignUpForm;
      setFormFields(loyaltySignUpForm);
      setBannerImg(
        res?.modules?.PROFILE?.headerImage
          ? res?.modules?.PROFILE?.headerImage
          : res?.modules?.HOME?.headerImage
      );
      setFooterImg(
        res?.modules?.PROFILE?.footerImage
          ? res?.modules?.PROFILE?.footerImage
          : res?.modules?.HOME?.footerImage
      );
      setSplashScreen(res?.splashScreen?.background);
      setThankYouScreen(res?.thankYouScreen?.background);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
      setPwa(setInitialValues(loyaltySignUpForm));
    });
  }, [business, tenant]);

  const getNameAndValue = (event) => [ event.target.name, event.target.value ];

  const handleEmailChange = (e) =>{
    const [name, value] = getNameAndValue(e);
    if (canAllowEdit(name, value)) {
      setIsInvalidEmail(!EMAIL_REGEXP.test(value));
      return value;
    }
  }

  function getValueAfterValidation(e, defaultVal) {
    switch (e.target.type) {
      case "email": return handleEmailChange(e);
      default: return handleDefaultChange(e, defaultVal);
    }
  }

  const handleDefaultChange = (e, defaultVal) =>{
    const [name, value] = getNameAndValue(e);
    if (canAllowEdit(name, value)) {
      return onEditAllowed(name, value);
    }
    return defaultVal
  }

  const handleChange = (e) => {
    const tempPwa = { ...pwa };
    tempPwa[e.target.name] = getValueAfterValidation(e, tempPwa[e.target.name]);
    setPwa(tempPwa);
  };

  const getSocialMediaParams = () => {
    const socialMediaParams = getParamsFromUrl(window.location.search, [
      "userId",
      "channel",
    ])
    return Object.values(SocialMediaChannel).includes(socialMediaParams.channel)
      ? socialMediaParams
      : {}
  }

  const getUpsertData = () =>{
    const pwaVal = {
      shoeSize : pwa.shoeSize,
      instagramId : pwa.instagramId,
      placeOfRegistration : pwa.placeOfRegistration,
      city : pwa.city,
      favStore : pwa.favStore,
      storePersonName : pwa.storePersonName
    };
    const dataVal = {
      email: pwa.email,
      customerName: pwa.name,
      customerMobile: pwa.mobile,
      gender: pwa.gender,
      dob: pwa.birthday && moment.utc(pwa.birthday).format(),
      anniversary: pwa.anniversary && moment.utc(pwa.anniversary).format(),
    }
    const socialMediaParams = getSocialMediaParams()
    return  {
      data: { ...dataVal, pwaDetails: { ...pwaVal }, ...socialMediaParams },
      tenant
    };
  }

  const handleSubmit = () => {
    const upsertData =  getUpsertData();

    const registerData = {
      tenant,
      mobile: pwa.mobile,
    };

    register(upsertData, registerData, setStatus, setIsSubmit);
  };

  const handleNameError = (fieldName) => {
    if (pwa[fieldName]?.length < 3) {
      return "Enter atleast 3 alphabetic letters";
    }
  }

  const handleMobileError = (fieldName) => {
    if (pwa[fieldName]?.length < 10) {
      return "Enter 10 digit valid mobile number";
    }
  }

  const handleEmailError = () => {
    if (isInvalidEmail) {
      return "Enter valid Email";
    }
  }

  const handleError = (fieldName) => {
    switch (fieldName) {
      case "name":
        return handleNameError(fieldName);
      case "mobile":
        return handleMobileError(fieldName);
      case "email":
        return handleEmailError();
      default:
        return '';
    }
  };

  
  const handleDisable = () => {
    const allEqual = (arr) => arr.every((val) => val === arr[0]);
    const check = formFields?.map((fields) => {
      return fields.mandatory === true && _.isEmpty(pwa[fields.name]);
    });

    const mandatoryCheckArray = _.filter(disableSubmit, (value)=> {return value}) 

    const empty = allEqual(check);
    if (
      pwa?.name?.length < 3 ||
      pwa?.mobile?.length !== 10 ||
      isInvalidEmail ||
      !empty || !_.isEmpty(mandatoryCheckArray) || 
        !agreed
    )
      return true;
    else {
      return false;
    }
  };

  function register(upsertData, registerData, setStatus, setIsSubmit) {
    userUpsert(upsertData) &&
      registerWithoutCaptcha(registerData).then((res) => {
        if (res.status === 200) {
          setStatus(
            " Thanks for signing up. A link has been sent to your mobile."
          );
          setIsSubmit(true);
        } else {
          setStatus("Something Went Wrong");
        }
      });
  }
  
  function renderDefault(pwa, handleChange) {
    return <Grid xs={12} item>
      <TextField
        name="mobile"
        value={pwa?.mobile}
        type="number"
        disabled={isFromOneFlow}
        style={{ width: "80%", textAlign: "left" }}
        label="Mobile Number"
        error={pwa?.mobile?.length < 10 ? true : false}
        helperText={pwa?.mobile?.length < 10
          ? "Enter 10 digit mobile number"
          : ""}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChange} />
    </Grid>;
  }
  
  function renderTextfield(field) {
      switch(field.fieldtype){
          case "date":
            return renderDateField()
          case "NikeComponent": 
            return renderNikeComponent()
          case "select":
            const selectFieldArray = assignSelectFieldArray(field.name)
            return renderSelectField(selectFieldArray)
          default:
            const inputValue = _.get(pwa, field.name, '');
              return renderDefaultField(inputValue)
      }

    function renderDefaultField(inputValue) {
      return <TextField
        key={field.name}
        name={field.name}
        type={field.fieldtype}
        disabled={field.name === 'mobile' && isFromOneFlow}
        value={inputValue}
        style={{ width: "80%", textAlign: "left" }}
        label={field.mandatory === true
          ? `${field.label}*`
          : field.label}
        error={!!handleError(field.name)}
        helperText={handleError(field.name)}
        onChange={handleChange}>
      </TextField>;
    }

    function renderSelectField(selectFieldArray) {
      return <SelectField field={field} pwa={pwa} handleError={handleError} handleChange={handleChange} selectFieldArray={selectFieldArray} />;
    }

    function renderDateField() {
      return <TextField
        key={field.name}
        name={field.name}
        type="date"
        value={_.get(pwa, 'field.name')}
        style={{ width: "80%", textAlign: "left" }}
        label={field.mandatory === true
          ? `${field.label}*`
          : field.label}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={field.fieldtype === "date" && {
          min: "1900-01-01",
          max: moment(`${maxDate}`).format("YYYY-MM-DD"),
        }}
        error={!!handleError(field.name)}
        helperText={handleError(field.name)}
        onChange={handleChange}>
      </TextField>;
    }
  }

  return (
    <>
      {splashScreen && loader && (
        <div>
          <img
            src={splashScreen}
            alt=""
            style={{ width: "100%", height: "100vh" }}
          />
        </div>
      )}
      {isSubmit === false && loader === false && (
        <>
          <HeroBanner src={bannerImg} />
          <Grid style={{ paddiing: "80px 5px 0 5px", margin: "30px 0px" }}>
            <center>
              <Card style={{ maxWidth: 600, paddingTop: 10, border: 0 }}>
                <Grid container spacing={5}>
                  {formFields?.length > 0 ? (
                    formFields.map((field, index) => {
                      return (
                        <Grid xs={12} item key={index}>
                          {renderTextfield(field, pwa, maxDate, handleError, handleChange, assignSelectFieldArray, gender)}
                        </Grid>
                      );
                    })
                  ) : (
                    renderDefault(pwa, handleChange)
                  )}
                </Grid>
                <div className={styles.termsContainer}>
		              <input type="checkbox" value={agreed} onChange={() => setAgreed(!agreed)}/>
		              <span className={styles.terms}>By checking this, I give explicit consent to receive personalized offers and promotional content using the above details.</span>
	</div>
              <Typography style={{ marginTop: 20, color: "red" }}>
                  {status}
                </Typography>
                {handleDisable() && (
                  <Typography style={{ fontSize: 13, color: "red" }}>
                    * Please Fill the Mandatory Fields
                  </Typography>
                )}

                <Button
                  variant="contained"
                  style={{ marginTop: 30 }}
                  onClick={handleSubmit}
                  disabled={handleDisable()}
                >
                  Submit
                </Button>
              </Card>
            </center>
          </Grid>
          <Footer img={footerImg} />
        </>
      )}

      {isSubmit && thankYouScreen && (
        <ThankYou thankYouScreen={thankYouScreen} />
      )}
    </>
  );

  function renderNikeComponent() {
    return <NikeComponent nikeData={pwa} setNikeData={setPwa} setDisableSubmit={setDisableSubmit} disableSubmit={disableSubmit} />;
  }
};

export default DynamicForm;
