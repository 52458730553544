import Home from "../components/Offers/Home";
import Profile from "../components/MyProfile";
import Form from "../components/ReferFriend/Form";
import ROUTE_PATHS from "./routesPath";

import LoyaltyInfo from "../components/LoyaltyInfo/LoyaltyInfo";
import Redeemed from "../components/Offers/OnlyForYou/Redeemed";
import ActivatedOffers from "../components/Offers/OnlyForYou/ActivatedOffers";
import CustomHTML from "../components/CustomHTML/CustomHTML";

const ROUTES = [
  {
    path: ROUTE_PATHS.HOME,
    component: Profile,
  },
  {
    path: ROUTE_PATHS.LOYALTY_INFO,
    component: LoyaltyInfo,
  },
  {
    path: ROUTE_PATHS.REFER_FRIEND,
    component: Form,
  },
  {
    path: ROUTE_PATHS.OFFERS,
    component: Home,
    nestedRoutes: [
      {
        path: ROUTE_PATHS.AVAILABLE_OFFERS,
        component: ActivatedOffers,
        exact: true,
      },
      { path: ROUTE_PATHS.REEDEMED_OFFERS, component: Redeemed },
    ],
  },
  {
    path : ROUTE_PATHS.HTML,
    component : CustomHTML,
  }
];

export default ROUTES;
