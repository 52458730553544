/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAppTheme from "../../../hooks/useAppTheme";
import ROUTE_PATHS from "../../../routes/routesPath";
import { LinkGroup } from "../../../styles/styledComponent";
import { ReactComponent as OfferSymbol } from "../../../assets/Offers.svg";
import { ReactComponent as ProfilePic } from "../../../assets/profile_icon.svg";
import { ReactComponent as Loyalty } from "../../../assets/Loyalty.svg";
import CustomLogo from "../../../assets/CustomLogo.js";
import { Box } from "@mui/system";

const ListOfLinks = ({
  links,
  component: Component = Link,
  onLinkClick,
  end = false,
  isMobileView,
}) => {
  const [menuval, setMenuval] = useState([]);
  const { theme } = useAppTheme();

  function setLink(navRoutes) {
    return Object.entries(links).map(([key, val]) => {
      const path = (val.module === 'HTML') ? `${navRoutes[val.module]}/${key}` : navRoutes[val.module];
      return {
        ...val,
        module: path,
      };
    });
  }
  
  useEffect(() => {
    const navRoutes = {
      OFFER: `${ROUTE_PATHS.OFFERS}/${ROUTE_PATHS.AVAILABLE_OFFERS}`,
      MY_PROFILE: `${ROUTE_PATHS.HOME}`,
      REFER_A_FRIEND: `${ROUTE_PATHS.REFER_FRIEND}`,
      LOYALTY_DETAILS: `${ROUTE_PATHS.LOYALTY_INFO}`,
      HTML : '/html'
    };

    const menus = async () => {
      const promise = await Promise.all(
        setLink(navRoutes)
      );
      setMenuval(promise);
    };
    menus();
  }, [links]);

  return (
    <LinkGroup sx={{ m: 4 }} color="black">
      {menuval &&
        menuval.map(({ name, module }) => (
          <li key={module + name}>
            <Component onClick={onLinkClick} to={module} end={end}>
              {isMobileView ? (
                <Box className="display">
                  {name === "Offer for You" ? (
                    <OfferSymbol />
                  ) : name === "My Profile" ? (
                    <ProfilePic />
                  ) : name === "Loyalty Details" ? (
                    <Loyalty className="margin" />
                  ) : (
                    <CustomLogo text={name.substring(0, 1)}/>
                  )}
                  <span
                    style={{
                      fontWeight: 700,
                      color: `${theme.palette.text.secondary}`,
                      marginLeft: 5,
                    }}
                  >
                    {name}
                  </span>
                </Box>
              ) : (
                <span
                  style={{
                    fontWeight: 700,
                    color: `${theme.palette.primary.main}`,
                  }}
                >
                  {name}
                </span>
              )}
            </Component>
          </li>
        ))}
    </LinkGroup>
  );
};

export default ListOfLinks;


