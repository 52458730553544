import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as Mail } from "../../assets/mail.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import { ReactComponent as Gender } from "../../assets/gender_pwaaa.svg";
import { ReactComponent as Anniversary } from "../../assets/Anniversary.svg";
import { ReactComponent as Dob } from "../../assets/dob.svg";
import { ReactComponent as InstagramId} from "../../assets/insta.svg";
import { ReactComponent as ShoeSize} from "../../assets/shoe.svg";
// import { ReactComponent as Store } from "../../assets/store.svg";
import { style, TextBox, TransactionCard } from "../../styles/styledComponent";
import { Close, Edit } from "@mui/icons-material";
import { getProfile, updateProfile } from "../../service/profile";
import TransactionHistory from "./TransactionHistory";
import useAuth from "../../hooks/useAuth";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { EMAIL_REGEXP } from "../../shared/constants/RegExp";

const UserProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState(dayjs(""));
  const [anniversary, setAnniversary] = useState(dayjs(""));
  const [instagramId, setInstagramId] = useState("");
  const [shoeSize, setShoeSize] = useState("");

  const auth = useAuth();
  const [expanded, setExpanded] = useState(false);

  const callApiProfile = () => {
    getProfile().then((res) => {
      setName(res.name);
      setEmail(res.email);
      setMobile(res.mobile);
      setGender(res.gender);
      setDob(res.dob);
      setAnniversary(res.anniversary);
      setInstagramId(res.instagramId);
      setShoeSize(res.shoeSize);
    });
  };

  useEffect(() => {
    callApiProfile();
  }, []);

  const handleClose = () => {
    setIsEdit(false);
    callApiProfile();
  };
  const handleUpdate = () => {
    const data = {
      data: {
        customerMobile: mobile,
        customerName: name,
        dob: dob && new Date(dob).toISOString(),
        anniversary: anniversary && new Date(anniversary).toISOString(),
        gender: gender,
        email: email
      },
      tenant: auth?.user?.business,
    };
    updateProfile(data);
    setIsEdit(false);
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleChange = (event) => {
    setGender(event.target.value);
  };

  return (
    <>
      <TransactionCard>
        <CardHeader
          style={{ padding: 0 }}
          action={
            <IconButton
              style={{ paddingRight: 27 }}
              onClick={() => setIsEdit(true)}
            >
              <Edit style={{ fontSize: 18 }} />
            </IconButton>
          }
          title={name || "Not Available"}
        />
        <CardContent style={{ padding: 0, paddingTop: 10 }}>
          <Box className="profilebox">
            <Mail style={{ marginRight: 5, marginTop: 2 }} />
            <span className="heading">{STRING_CONSTANTS.EMAIL} </span>
          </Box>
          <Typography style={{ marginBottom: 10, marginLeft: 30 }}>
            {email || "Not Available"}
          </Typography>

          <Box className="profilebox">
            <Phone style={{ marginRight: 5, marginTop: 2 }} />
            <span className="heading">{STRING_CONSTANTS.PHONE_NUMBER} </span>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: 25,
            }}
          >
            <Typography style={{ marginBottom: 10, marginLeft: 30 }}>
              {mobile || "Not Available"}
            </Typography>
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
          </Box>
        </CardContent>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent
            style={{ paddingTop: 20, paddingLeft: 0, paddingBottom: 0 }}
          >
            <Box className="profilebox">
              <Dob style={{ marginRight: 10 }} />
              <span className="heading">DOB </span>
            </Box>
            <Typography style={{ marginBottom: 10, marginLeft: 30 }}>
              {dob ? moment(`${dob}`).format("DD-MM-YYYY") : "Not Available"}
            </Typography>

            <Box className="profilebox">
              <Gender style={{ marginRight: 10 }} />
              <span className="heading">Gender </span>
            </Box>
            <Typography style={{ marginBottom: 10, marginLeft: 30 }}>
              {gender || "Not Available"}
            </Typography>

            <Box className="profilebox">
              <Anniversary style={{ marginRight: 10 }} />
              <span className="heading">Anniversary </span>
            </Box>
            <Typography style={{ marginBottom: 10, marginLeft: 30 }}>
              {anniversary
                ? moment(`${anniversary}`).format("DD-MM-YYYY")
                : "Not Available"}
            </Typography>

            {
              (shoeSize) ?
              <>
            <Box className="profilebox">
              <ShoeSize style={{ marginRight: 10 }} />
              <span className="heading">Shoe Size </span>
            </Box>
            <Typography style={{ marginBottom: 10, marginLeft: 30 }}>
              {shoeSize || "Not Available"}
            </Typography></> : undefined
            }

            {
              (instagramId) ?
              <>
            <Box className="profilebox">
              <InstagramId style={{ marginRight: 10 }} />
              <span className="heading">Instagram Id </span>
            </Box>
            <Typography style={{ marginBottom: 10, marginLeft: 30 }}>
              {instagramId || "Not Available"}
            </Typography></> : undefined
            }

          </CardContent>
        </Collapse>

        <Modal open={isEdit} onClose={handleClose}>
          <Box sx={style}>
            <IconButton
              style={{ position: "absolute", top: 10, right: 40 }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
            <Typography
              variant="h6"
              style={{ fontWeight: 600, marginBottom: 30 }}
            >
              Edit Profile
            </Typography>
            <TextBox
              label="Name*"
              name="name"
              value={name}
              InputLabelProps={{
                shrink: true,
                style: { color: "black" },
              }}
              error={name?.length < 3 && name?.length !== 0 && true}
              helperText={
                name?.length < 3 &&
                name?.length !== 0 &&
                "Enter atleast 3 alphabetic letters"
              }
              onChange={(e) =>
                setName(e.target.value.replace(/[^a-zA-Z\s]/g, ""))
              }
            />
            <TextBox
              label="Email*"
              name="email"
              value={email}
              InputLabelProps={{
                shrink: true,
                style: { color: "black" },
              }}
              error={!EMAIL_REGEXP.test(email) && email?.length !== 0 && true}
              helperText={
                !EMAIL_REGEXP.test(email) &&
                email?.length !== 0 &&
                "Enter Valid Email-Id"
              }
              onChange={(e) => {
                if (EMAIL_REGEXP.test(e.target.value)) {
                  setEmail(e.target.value);
                } else {
                  setEmail(e.target.value);
                }
              }}
            />

            <Box style={{ width: "82%", marginLeft: "9%" }}>
              <FormControl fullWidth>
                <InputLabel id="select-label" style={{ color: "black" }}>
                  Gender
                </InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={gender || ""}
                  label="Gender"
                  onChange={handleChange}
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Trans-Gender">Trans-Gender</MenuItem>
                  <MenuItem value="Prefer not to Disclose">
                    Prefer not to Disclose
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
              <Box style={{ marginTop: 25 }}>
                <DatePicker
                  disableFuture
                  label="Date of Birth"
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={dob}
                  onChange={(newValue) => {
                    setDob(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: "82%", "&.focued": { color: "black" } }}
                    />
                  )}
                />
              </Box>
              <Box style={{ marginTop: 25 }}>
                <DatePicker
                  disableFuture
                  name="anniversary"
                  label="Anniversary"
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={anniversary}
                  onChange={(newValue) => {
                    setAnniversary(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="anniversary"
                      {...params}
                      style={{ width: "82%" }}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>

            <Button
              style={{ marginTop: 30 }}
              variant="contained"
              onClick={handleUpdate}
              disabled={
                (name?.length < 3) ||
                (!EMAIL_REGEXP.test(email) && email?.length !== 0)
                  ? true
                  : false
              }
            >
              Update
            </Button>
          </Box>
        </Modal>
      </TransactionCard>

      <div style={{ textAlign: "center", fontWeight: 700 }}>
        <span>
          Transaction History
        </span>
      </div>

      <TransactionHistory />
    </>
  );
};

export default UserProfile;
