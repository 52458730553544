import axios from "axios";
import config from "../config";
const { ruleServerUrl } = config;

export const getSignup = (tenant, business) => {
  return axios
    .get(`${ruleServerUrl}/pwa/register/${tenant}/${business}`)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};

export const userUpsert = (data) => {
  return axios
    .post(`${ruleServerUrl}/user-upsert`, data)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};
export const registerWithoutCaptcha = (data) => {
  return axios
    .post(`${ruleServerUrl}/my-offers/register-without-captcha`, data)
    .then((response) => response)
    .catch((err) => {
      return err;
    });
};
