const gender = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Trans-Gender",
      value: "Trans-gender",
    },
    {
      label: "Prefer not to Disclose",
      value: "Prefer not to disclose",
    },
  ];

  const shoeSizes = [
  {label: 'UK 3', value: 'UK 3'},
  {label: 'UK 4', value: 'UK 4'},
  {label: 'UK 5', value: 'UK 5'},
  {label: 'UK 6', value: 'UK 6'},
  {label: 'UK 7', value: 'UK 7'},
  {label: 'UK 8', value: 'UK 8'},
  {label: 'UK 9', value: 'UK 9'},
  {label: 'UK 10', value: 'UK 10'},
  {label: 'UK 11', value: 'UK 11'},
  {label: 'UK 12', value: 'UK 12'}
];

const placeOfRegistration = [
  'Home', 
  'Store'
]

const cities = [
  'Ahmedabad', 'Bengaluru', 'Delhi', 'Gurgaon', 'Mumbai', 'Pune'
]

const storesInCities = {
  "Delhi":
  [
      "Nike, Vegas Dwarka",
      "Nike, Ambience Mall",
      "Nike, Jawahar Nagar Kamla Ngr",
      "Nike, Sec-8, Rohini",
      "Nike, CP New (B-Block) (BDA Co.)",
      "Nike, Southex",
      "Nike, Select Mall",
      "Nike, DLF Vasant Kunj"
  ],
  "Pune":
  [
      "Nike, JM Road",
      "Nike, MG Road(308)",
      "Nike, My Mall Pune (Pavellion Mall)",
      "Nike, Phoenix Market City"
  ],
  "Mumbai":
  [
      "Nike, Colaba",
      "Nike, Linking1",
      "Nike, Colaba-4 (COCO)",
      "Nike,JWD",
      "Nike, Ramee Emerald,Linking Road",
      "Nike, Andheri Infinity Mall",
      "Nike, HSP Flagship Mumbai",
      "Nike, Ghatkopar Mumbai",
      "Nike, Phoenix Bandra Kurla (Mum)"
  ],
  "Gurgaon":
  [
      "Nike, Ambiance Mall Gurgaon",
      "Nike, MGF Gurgaon",
      "Nike, Cyber Hub",
      "Nike, Mantri Mall"
  ],
  "Bengaluru":
  [
      " Nike, Indra Nagar",
      "Nike, Brigade New",
      "Nike, Forum Mall",
      "Nike, Jaya Nagar",
      "Nike, Orion Mall",
      "Nike, Phoenix Mall",
      "Nike - Phoenix Mall of Asia"
  ],
  "Ahmedabad":
  [
      "Nike, One Mall",
      "Nike, CG Road, New Ahmedabad"
  ]
}

const DefaultFavIcon = "%PUBLIC_URL%/favicon.ico"

export {
  gender,
  shoeSizes,
  placeOfRegistration,
  cities,
  storesInCities,
  DefaultFavIcon,
}