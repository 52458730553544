import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";
import {
  DateBox,
  InvoiceBtn,
  TransactionCard,
} from "../../styles/styledComponent";
import { ReactComponent as DownloadIcon } from "../../assets/Download.svg";
import { ReactComponent as NoTransaction } from "../../assets/transactions.svg";
import loader from "../../assets/Fountain.gif";
import _ from "lodash";
import {
  downloadInvoice,
  getTransactionHistory,
} from "../../service/loyaltyinfo.service";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import IfEmpty from "../../shared/constants/IfEmpty";
import useAppConfig from "../../hooks/useAppConfig";
import { Permissions } from "../../shared/constants/FeaturePermissions"
import { toast } from "react-hot-toast";

const TransactionHistory = () => {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [returnBills, setReturnBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoiceDownloading,setInvoiceDownloading] = useState(false);

  const Config = useAppConfig();
  const Business = Config?.appConfig?.selecteBussines?.name;

  const decodedData = useAuth();
  useEffect(() => {
    setLoading(true);
    getTransactionHistory(Business).then((response) => {
      setTransactionHistory(response.bills);
      setReturnBills(response.returnedBills);
      setLoading(false);
    });
  }, [Business]);

  
  const handleInvoiceDownload = async ({ generatedBillId, invoiceType }) => {
    try {
      setInvoiceDownloading(true);
      toast(`Invoice PDF Generation for ${generatedBillId} has been started.`);
      const payload = {
        billId: generatedBillId,
        tenant: _.get(decodedData, "user.tenantId"),
        customerMobileNo: _.get(decodedData, "user.mobile"),
        businessUnitId: _.get(Config, "appConfig.selecteBussines.id"),
        invoiceType: invoiceType,
      }
      console.log("debug-payload", payload);
      await downloadInvoice(payload)
    } catch (err) {
      toast(`Error downloading invoice for ${generatedBillId}!!`)
    } finally {
      setInvoiceDownloading(() => {
        toast(`Downloaded invoice for ${generatedBillId}!!`)
        return false
      })      
    }
  }

  const Bills = _.concat(transactionHistory, returnBills);

  const transactionBills = _.sortBy(Bills, (e) => {
    return e.date;
  }).reverse();

  const doesHavePermission = (permission, AppConfig) => _.get(AppConfig, "config.permissions.enabled",[]).includes(permission)
  const doesHaveInvoicePermission = doesHavePermission(
    Permissions.InvoiceDownload,
    Config.appConfig
    )
  const renderDownloadInvoice = ({ generatedBillId, invoiceType }) => {
    if (!doesHaveInvoicePermission) 
      return <></>
    
    return (
      <InvoiceBtn
        variant="contained"
        onClick={() =>
          handleInvoiceDownload({
            generatedBillId,
            invoiceType,
          })
        }
        disabled={invoiceDownloading}
      >
        <p style={{ display: "flex", justifyContent: "center" }}>
          <DownloadIcon className="download" /> Invoice
        </p>
      </InvoiceBtn>
    )
  }

  return (
    <div>
      {loading ? (
        <div className="loadingGif">
          <img src={loader} alt="Loading..." />
        </div>
      ) : (
        transactionBills?.map((obj, index) => {
          return (
            <TransactionCard key={index + 1}>
              <Typography style={{ color: "#6A6A6A", marginBottom: 5 }}>
                {STRING_CONSTANTS.BILL_ID}
                <span
                  style={{ fontWeight: 600, color: "#333333", fontSize: 14 }}
                >
                  {" "}
                  {obj.billNo}
                </span>
              </Typography>

              <Typography style={{ color: "#6A6A6A", marginBottom: 5 }}>
                Bill Date:
                <span
                  style={{ fontWeight: 600, color: "#333333", fontSize: 14 }}
                >
                  {" "}
                  {moment(`${obj?.date}`).format("DD-MM-YYYY")}
                </span>
              </Typography>

              <Typography style={{ color: "#6A6A6A", marginBottom: 5 }}>
                Invoice Type:
                <span
                  style={{ fontWeight: 600, color: "#333333", fontSize: 14 }}
                >
                  {" "}
                  {obj.invoiceType === "IN" && "Invoice"}
                  {obj.invoiceType === "SR" && "Sales Return"}
                  {obj.invoiceType === "VB" && "Void Bill"}
                </span>
              </Typography>

              <Typography style={{ color: "#6A6A6A", marginBottom: 5 }}>
                Store Name:
                <span
                  style={{ fontWeight: 600, color: "#333333", fontSize: 14 }}
                >
                  {" "}
                  {obj.storeName}
                </span>
              </Typography>

              <DateBox>
                <Typography style={{ color: "#6A6A6A" }}>
                  Bill Total:{" "}
                  <span style={{ color: "#F07663", fontWeight: 600 }}>
                    INR {obj.totalAmount ? obj.totalAmount : "0"}
                  </span>
                </Typography>
                {renderDownloadInvoice(obj)}
              </DateBox>
            </TransactionCard>
          );
        })
      )}

      {transactionBills?.length === 0 && (
        <div style={{ padding: 15 }}>
          <IfEmpty
            Icon={NoTransaction}
            head="Sorry, no transactions found"
            desc="Once the first bill is raised you
              will be able to see your transactions"
          />
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
