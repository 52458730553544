import { useEffect, useState } from "react";
import useAppConfig from "../../hooks/useAppConfig";
import Footer from "../../Layout/Footer";
import HeroBanner from "../../Layout/HeroBanner";
import { LoyaltyInfoContainer } from "../../styles/styledComponent";
import LoyaltyPoints from "./LoyaltyPoints";

const LoyaltyInfo = () => {
  const [bannerImg, setBannerImg] = useState();
  const [footerImg, setFooterImg] = useState();
  const pwaConfig = useAppConfig();
  useEffect(() => {
    setBannerImg(
      pwaConfig?.appConfig?.config?.modules?.LOYALTY?.headerImage
        ? pwaConfig?.appConfig?.config?.modules?.LOYALTY?.headerImage
        : pwaConfig?.appConfig?.config?.modules?.HOME?.headerImage
    );
    setFooterImg(
      pwaConfig?.appConfig?.config?.modules?.LOYALTY?.footerImage
        ? pwaConfig?.appConfig?.config?.modules?.LOYALTY?.footerImage
        : pwaConfig?.appConfig?.config?.modules?.HOME?.footerImage
    );
  }, [pwaConfig]);
  return (
    <>
      <LoyaltyInfoContainer>
        <HeroBanner src={bannerImg} />
        <LoyaltyPoints />
        <Footer img={footerImg} />
      </LoyaltyInfoContainer>
    </>
  );
};

export default LoyaltyInfo;
