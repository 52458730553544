import { Box, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getLoyaltyInfo } from "../../service/loyaltyinfo.service";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";
import { LoyaltyPointsCard } from "../../styles/styledComponent";
import LoyaltyPointsChart from "./LoyaltyPointsChart";
import useAppConfig from "../../hooks/useAppConfig";
import TableWithAPI from "./TableWithApi";
import { get } from "lodash";
import { toast } from "react-hot-toast";
import { doesUserHaveImmediateExpiringPoints } from "../../service/loyalty-points"
import moment from "moment"
import _ from 'lodash';

const LoyaltyPoints = () => {
  const [loyaltyInfo, setLoyaltyInfo] = useState({});
  const config = useAppConfig();

  const selectedBusiness = get(config, "appConfig.selecteBussines.name")
  useEffect(() => {
    selectedBusiness &&
      getLoyaltyInfo(selectedBusiness)
        .then(setLoyaltyInfo)
  }, [selectedBusiness])

  const getFormattedExpiryPointsToast = ({
    expiringpoints,
    max_expire_date,
  }) => {
    const formattedDate = moment(max_expire_date).fromNow()
    return `${expiringpoints} points are going to expire ${formattedDate}`
  }

  useEffect(() => {
    if (doesUserHaveImmediateExpiringPoints(config)) {
      toast(
        getFormattedExpiryPointsToast(
          get(config, "loyaltyDetails.immediateExpiringPoints", {})
        )
      )
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
    {
      (loyaltyInfo?.response?.data?.status !== 'failure') && !_.isEmpty(loyaltyInfo) &&
      <LoyaltyPointsCard>
      <CardContent>
        <header>
          <Box>
            <Typography style={{ fontWeight: 500 }}>
              {STRING_CONSTANTS.LOYALTY_POINTS}
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              style={{ fontWeight: 600 }}
            >
              {loyaltyInfo?.active?.toString() || "-"}
            </Typography>
          </Box>
          <Box>
            <Typography style={{ fontWeight: 500 }}>
              {STRING_CONSTANTS.LOYALTY_LEVEL}
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              style={{ fontWeight: 600 }}
            >
              {loyaltyInfo?.currentTier || "-"}
            </Typography>
          </Box>
        </header>
        <Box className="loyaltyChart">
          <LoyaltyPointsChart
            earned={loyaltyInfo?.active || 0}
            redeemed={loyaltyInfo?.burned || 0}
            expired={loyaltyInfo?.expired || 0}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography style={{ fontSize: 18 }}>Total Earned: </Typography>
          <span
            style={{
              fontWeight: 700,
              marginLeft: 4,
              color: "#F26E5F",
              fontSize: 18,
            }}
          >
            {" "}
            {loyaltyInfo?.earned?.toString() || ""}
          </span>
        </Box>
      </CardContent>
    </LoyaltyPointsCard>
    }

      <Typography style={{ textAlign: "center", fontWeight: 600 }}>
        Loyalty Points Details
      </Typography>
      <LoyaltyPointsCard>
        <TableWithAPI />
      </LoyaltyPointsCard>
    </>
  );
};

export default LoyaltyPoints;
