const ROUTE_PATHS = {
  HOME: "/",
  OFFERS: "/offer",
  AVAILABLE_OFFERS: "available-offers",
  REEDEMED_OFFERS: "reedemed-offers",
  LOYALTY_INFO: "/loyalty-info",
  REFER_FRIEND: "/refer-friend",
  REGISTER: `register/:tenant/:business`,
  ERROR: "/fail",
  HTML : `/html/:moduleName`,
  QR_REDIRECT: `/customer-verify/:tenant/:business`,
  SIGN_UP: `sign-up/:tenant/:business`
};

export default ROUTE_PATHS;
