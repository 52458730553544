import React, {useState, useEffect} from 'react';
import sanitizeHtml from 'sanitize-html';
import HeroBanner from "../../Layout/HeroBanner";
import Footer from "../../Layout/Footer";
import useAppConfig from "../../hooks/useAppConfig";

function getAllowedTags() {
	const defaultTags = [
		"address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
		"h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
		"dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
		"ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
		"em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
		"small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
		"col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"
	];
	return defaultTags.concat(['img', 'style', 'meta', 'link']);
}

const defaultOptions = {
	allowedTags: getAllowedTags(),
	allowedAttributes: {
		'link' : ['href', 'rel', 'type'],
		'*' : ['class', 'id'],
	},
	nonTextTags: [ 'style', 'script', 'textarea', 'option', 'title'],
	// can lead to CORS error, add entry in nginx to allow requests.
	allowedIframeHostnames: [
		'rules.casa.ajira.tech',
		'rules.casaqa.ajira.tech',
		'rules.qa.casaretail.ai',
	]
}

const sanitize = (dirty, options={}) => ({
	__html: sanitizeHtml(dirty, { ...defaultOptions, ...options })
});

const SanitizeHTML = ({html, options={}, moduleName}) => {

	const pwaConfig = useAppConfig();
	const [bannerImg, setBannerImg] = useState("");
	const [footerImg, setFooterImg] = useState("");

	useEffect(() => {
		setBannerImg(
		  pwaConfig.appConfig.config.modules[moduleName]?.headerImage
			? pwaConfig.appConfig.config.modules[moduleName].headerImage
			: pwaConfig.appConfig.config.modules.HOME.headerImage
		);
		setFooterImg(
		  pwaConfig.appConfig.config.modules[moduleName]?.footerImage
			? pwaConfig.appConfig.config.modules[moduleName].footerImage
			: pwaConfig.appConfig.config.modules.HOME.footerImage
		);
	  }, [pwaConfig, moduleName]);
  return (
  <>
	<HeroBanner src={bannerImg} />
	<div dangerouslySetInnerHTML={sanitize(html, options)} />
	<Footer img={footerImg} />
  </>
  )
};

export default SanitizeHTML;
